import Color from 'color';
import { Settings } from 'luxon';
import { defineStore } from 'pinia';
import { PrimeVueChangeTheme, PrimeVueConfiguration } from 'primevue/config';
import bgThemes from '@/codelists/BgThemes';
import colorThemes from '@/codelists/ColorThemes';
import languages from '@/codelists/Languages';
import { IExtentedClinic } from '@/interfaces/IExtendedClinic';
import i18n, { loadLocaleMessages, setI18nLanguage } from '@/plugins/i18n';
import { setPrimeVueLanguage } from '@/plugins/primeVue';
import { APISharedCodelistDto } from '../../generated';

interface ILoggedInUser {
    userName: string;
    avatar: string;
    appointments: number; // Number of appointments
    idClient: string;
    idCompany: string;
    idPartnership: string;
    idLocation: string; // Show/hide dashboard if user id location is in the list of locations
}

interface IState {
    languageCode: string;
    currencyCode: string;
    phonePrefix: string;
    clinics: IExtentedClinic[];
    codelists: {
        [key: string]: Array<APISharedCodelistDto>;
    };
    isThemeFromUrl: boolean;
    isThemeLight: boolean;
    openStaffId: string;
    defaultFormValues: {
        firstName: string;
        lastName: string;
        email: string;
        phonePrefix: string;
        phone: string;
        dateOfBirth: string;
    };
    htmlScriptExecuted: boolean[];
    loggedInUser: ILoggedInUser;
    isWizardDataLoaded: boolean;
}

export const useUserStore = defineStore('user', {
    state: (): IState => ({
        languageCode: '',
        currencyCode: '',
        phonePrefix: '',
        clinics: [],
        codelists: {},
        isThemeFromUrl: false,
        isThemeLight: true,
        openStaffId: '',
        defaultFormValues: {
            firstName: '',
            lastName: '',
            email: '',
            phonePrefix: '',
            phone: '',
            dateOfBirth: '',
        },
        htmlScriptExecuted: [],
        loggedInUser: {
            userName: '',
            avatar: '',
            appointments: 0,
            idClient: '',
            idCompany: '',
            idPartnership: '',
            idLocation: '',
        },
        isWizardDataLoaded: false,
    }),
    getters: {
        getLanguageCode: (state: IState) => state.languageCode,
        getCurrencyCode: (state: IState) => state.currencyCode,
        getClinics: (state: IState) => state.clinics,
        getDefaultFormValues: (state: IState) => state.defaultFormValues,
        getPhonePrefix: (state: IState) => state.phonePrefix,
        getPhonePrefixes: (state: IState) => {
            return state.codelists?.PHONE_PREFIX?.map((prefixItem) => ({
                code: prefixItem.code,
            }));
        },
        getHtmlScriptExecuted: (state: IState) => {
            return (scriptId: string): boolean => {
                return state.htmlScriptExecuted[scriptId] ?? false;
            };
        },
        getIsThemeLight: (state: IState) => state.isThemeLight,
    },
    actions: {
        async setLanguage(languageCode: string, primeVueInstance?: { config: PrimeVueConfiguration; changeTheme: PrimeVueChangeTheme }) {
            // Check if language is supported inside code list
            if (!languages.find((language) => language.code === languageCode)) {
                return;
            }

            this.languageCode = languageCode;

            // Load locale messages
            if (!i18n.global.availableLocales.includes(languageCode)) {
                await loadLocaleMessages(i18n, languageCode);
            }

            // Set i18n language
            setI18nLanguage(i18n, languageCode);

            // Set luxon locale
            Settings.defaultLocale = languageCode;

            // Set primevue language
            setPrimeVueLanguage(primeVueInstance, languageCode);
        },
        setCurrency(currencyCode: string) {
            this.currencyCode = currencyCode;
        },
        setPhonePrefix(phonePrefix: string) {
            this.phonePrefix = phonePrefix;
        },
        setClinics(clinics: IExtentedClinic[]) {
            this.clinics = clinics;
        },
        setCodelists(codelists: { [key: string]: Array<APISharedCodelistDto> }) {
            this.codelists = codelists;
        },
        setThemeFromUrl() {
            this.isThemeFromUrl = true;
        },
        setOpenStaffId(openStaffId: string | null) {
            this.openStaffId = openStaffId;
        },
        setDefaultFormValues(defaultFormValues: { firstName?: string; lastName?: string; email?: string; phone?: string; phonePrefix?: string; dateOfBirth?: Date }) {
            this.defaultFormValues = defaultFormValues;
        },
        setHtmlScriptExecuted(htmlScriptExecuted: boolean, scriptId: string = '-1') {
            this.htmlScriptExecuted[scriptId] = htmlScriptExecuted;
        },
        setTheme(color?: string | null, idScheme?: string | null): void {
            if (!color || !idScheme) return;

            const root = document.querySelector(':root');

            // Find booking scheme code from codelist
            const bookingScheme = this.codelists['BOOKING_SCHEME']?.find((list) => list.idCodelist === idScheme);

            if (bookingScheme && bookingScheme.code) {
                // Remove all classes from root
                root.setAttribute('class', '');

                // Remove all styles from root
                root.removeAttribute('style');

                // Get bg theme
                let bgTheme = bgThemes.find((themeBg) => themeBg.idCodeList === bookingScheme.code);

                if (!bgTheme) {
                    bgTheme = bgThemes[0]; // Set default bg theme
                }

                // Get color theme
                const colorTheme = colorThemes.find((themeCol) => themeCol.hexColor === color);

                // Add bg theme and color theme to root
                if (colorTheme) {
                    root.classList.add(bgTheme.themeName, bgTheme.isSpecial ? colorTheme.specialThemeName : colorTheme.themeName);
                } else {
                    // If color is not in color themes, manipulate the default color theme
                    const LIGHTER_PERC = 0.1;
                    const DARKER_PERC = 0.1;

                    // eslint-disable-next-line new-cap
                    const clr = Color(color);

                    const primaryStr = clr.rgb().string();
                    const secondaryStr = clr.darken(DARKER_PERC).rgb().string();
                    const primaryLighterStr = clr.lighten(LIGHTER_PERC).rgb().string();

                    const doc = document.documentElement;

                    doc.style.setProperty('--primary', primaryStr);
                    doc.style.setProperty('--primary-lighter', primaryLighterStr);
                    doc.style.setProperty('--secondary', secondaryStr);

                    if (!bgTheme.isSpecial) {
                        doc.style.setProperty('--select-item-bg-color', clr.rgb().alpha(0.5).string());
                        doc.style.setProperty('--select-item-border-color', clr.rgb().alpha(0.6).string());
                    }
                    if (bgTheme.isSpecial) {
                        doc.style.setProperty('--item-bg-color', secondaryStr);
                        doc.style.setProperty('--staff-detail-section-item-bg', secondaryStr);
                        doc.style.setProperty('--second-item-bg-color', secondaryStr);
                        doc.style.setProperty('--item-bg-hover-color', primaryLighterStr);
                        doc.style.setProperty('--content-background', primaryStr);
                        doc.style.setProperty('--button-panel-background', secondaryStr);
                        doc.style.setProperty('--button-panel-box-shadow-color', primaryLighterStr);
                    }
                    root.classList.add(bgTheme.themeName);
                }

                // Set theme lightness
                this.isThemeLight = bgTheme.isLight;
            }
        },
        setLoggedInUser(loggedInUser: ILoggedInUser) {
            this.loggedInUser = loggedInUser;
        },
        setWizardDataLoaded(isWizardDataLoaded: boolean) {
            this.isWizardDataLoaded = isWizardDataLoaded;
        },
        clear() {
            this.loggedInUser = {
                userName: '',
                avatar: '',
                appointments: 0,
                idClient: '',
                idCompany: '',
                idPartnership: '',
            };
            this.defaultFormValues = {
                firstName: '',
                lastName: '',
                email: '',
                phonePrefix: '',
                phone: '',
                dateOfBirth: '',
            };
        },
    },
    // Persist clinics because the Dashboard needs this data. If his data will be loaded from different place than WizardLayout it can generate any issues.
    persist: {
        paths: ['languageCode', 'loggedInUser', 'clinics'],
    },
});
