export interface IColorTheme {
    hexColor: string;
    themeName: string;
    specialThemeName: string;
}

const colorThemes: Array<IColorTheme> = [
    {
        hexColor: '#659be0',
        themeName: 'cornflower-blue-color-theme',
        specialThemeName: 'cornflower-blue-special-theme',
    },
    {
        hexColor: '#ed1c24',
        themeName: 'red-color-theme',
        specialThemeName: 'red-special-theme',
    },
    {
        hexColor: '#80c343',
        themeName: 'lime-green-color-theme',
        specialThemeName: 'lime-green-special-theme',
    },
    {
        hexColor: '#f7941e',
        themeName: 'sandy-brown-color-theme',
        specialThemeName: 'sandy-brown-special-theme',
    },
    {
        hexColor: '#65e09b',
        themeName: 'medium-spring-green-color-theme',
        specialThemeName: 'medium-spring-green-special-theme',
    },
    {
        hexColor: '#e0659b',
        themeName: 'orchid-color-theme',
        specialThemeName: 'orchid-special-theme',
    },
    {
        hexColor: '#337ab7',
        themeName: 'steel-blue-color-theme',
        specialThemeName: 'steel-blue-special-theme',
    },
    {
        hexColor: '#36c6d3',
        themeName: 'torquoise-color-theme',
        specialThemeName: 'torquoise-special-theme',
    },
    {
        hexColor: '#ed6b75',
        themeName: 'tomato-color-theme',
        specialThemeName: 'tomato-special-theme',
    },
];

export default colorThemes;