import { createRouter, createWebHistory } from 'vue-router';
import { ROUTES } from '@/enums/RoutesEnum';
import { useAuthStore } from '@/stores/auth';
import { useUserStore } from '@/stores/user';
import { useWizardStore } from '@/stores/wizard';
import type { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: {
            name: ROUTES.WIZARD,
        },
    },
    {
        path: '/login',
        name: ROUTES.LOGIN,
        component: () => import('@/pages/Login.vue'),
        beforeEnter: (to, from, next) => {
            const authstore = useAuthStore();
            if (authstore.getIsLoggedIn) {
                next({
                    name: ROUTES.CLINIC_SELECTION,
                });
            } else {
                next();
            }
        },
    },
    {
        path: '/dashboard',
        name: ROUTES.DASHBOARD,
        component: () => import('@/pages/Dashboard/Dashboard.vue'),
        beforeEnter: (to, from, next) => {
            const authStore = useAuthStore();
            const userStore = useUserStore();

            // Check if user clinic have active dashboard
            if (authStore.getIsLoggedIn) {
                const userClinic = userStore.clinics.find((clinic) => clinic.id === userStore.loggedInUser.idLocation);

                if (userClinic?.isBookingDashboard) {
                    next();
                } else {
                    next({
                        name: ROUTES.CLINIC_SELECTION,
                    });
                }
            } else {
                next({
                    name: ROUTES.WIZARD,
                });
            }
        },
    },
    {
        path: '/wizard',
        name: ROUTES.WIZARD,
        component: () => import('@/layout/WizardLayout.vue'),
        redirect: {
            name: ROUTES.CLINIC_SELECTION,
        },
        beforeEnter: (to, from, next) => {
            const wizardStore = useWizardStore();

            // Always allow navigation to the clinic selection route
            if (to.name === ROUTES.CLINIC_SELECTION) {
                return next();
            }

            // Define a map of routes to their corresponding steps
            const routeStepMap = {
                [ROUTES.SERVICE_SELECTION]: 0,
                [ROUTES.STAFF_SELECTION]: 1,
                [ROUTES.CLIENT_FORM]: 2,
            };

            // Get the corresponding step for the route
            const stepIndex = routeStepMap[to.name];

            if (stepIndex !== undefined) {
                // Check if the corresponding step is valid
                if (wizardStore.steps[stepIndex].isValid) {
                    return next();
                } else {
                    // Redirect to the previous step if the current step is not valid
                    const previousRoute = Object.keys(routeStepMap).find((key) => routeStepMap[key] === stepIndex - 1);
                    return next({ name: previousRoute });
                }
            }

            // If route is not recognized, redirect to the clinic selection
            next({ name: ROUTES.CLINIC_SELECTION });
        },
        children: [
            {
                name: ROUTES.CLINIC_SELECTION,
                path: 'clinic-selection',
                component: () => import('@/pages/ClinicSelection.vue'),
            },
            {
                name: ROUTES.SERVICE_SELECTION,
                path: 'service-selection',
                component: () => import('@/pages/ServiceSelection.vue'),
            },
            {
                name: ROUTES.STAFF_SELECTION,
                path: 'staff-selection',
                component: () => import('@/pages/Staff/StaffSelection.vue'),
            },
            {
                name: ROUTES.CLIENT_FORM,
                path: 'client-form',
                component: () => import('@/pages/ClientForm.vue'),
            },
        ],
    },
    {
        path: '/summary',
        name: ROUTES.SUMMARY,
        component: () => import('@/pages/Summary.vue'),
        beforeEnter: (to, from, next) => {
            if (from.name === ROUTES.CLIENT_FORM) {
                next();
            } else {
                next({
                    name: ROUTES.CLINIC_SELECTION,
                });
            }
        },
        props: (route) => ({
            orderErrorCode: route.query.orderErrorCode,
            appointmentId: route.query.appointmentId,
        }),
    },
    {
        path: '/payment/:appointmentId',
        name: ROUTES.PAYMENT_SUMMARY,
        component: () => import('@/pages/PaymentCallback.vue'),
        props: (route) => ({
            resultCode: route.query.resultCode, // CSOB result code
            paymentStatus: route.query.paymentStatus, // CSOB payment status
        }),
    },
    {
        path: '/Booking/Cancel',
        name: ROUTES.CANCEL_ORDER,
        component: () => import('@/pages/CancelOrder.vue'),
        props: (route) => ({
            appointmentId: route.query.aud,
            companyId: route.query.cud,
        }),
    },
    {
        path: '/clinicSelection',
        redirect: {
            name: ROUTES.CLINIC_SELECTION,
        },
    },
    {
        path: '/service-selection',
        redirect: {
            name: ROUTES.SERVICE_SELECTION,
        },
    },
    {
        path: '/404',
        component: () => import('@/pages/NotFound.vue'),
        name: ROUTES.NOT_FOUND,
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: {
            name: ROUTES.CLINIC_SELECTION,
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { left: 0, top: 0 };
    },
});

export default router;
