import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import { RequestTypeEnum } from '@/enums/RequestTypeEnum';
import { api } from '@/services/api';
import { useUserStore } from '@/stores/user';
import { useWizardStore } from '@/stores/wizard';

interface IToken {
    token: string | null;
    expiresIn: number | null;
}

interface IState {
    accessToken: IToken;
    refreshToken: IToken;
}

export const useAuthStore = defineStore('auth', {
    state: (): IState => ({
        accessToken: {
            token: null,
            expiresIn: null,
        },
        refreshToken: {
            token: null,
            expiresIn: null,
        },
    }),
    getters: {
        getAccessToken: (state: IState) => state.accessToken,
        getRefreshToken: (state: IState) => state.refreshToken,
        getIsLoggedIn: (state: IState) => !!state.accessToken?.token,
    },
    actions: {
        setAccessToken(accessToken: IToken) {
            this.accessToken = accessToken;
        },
        setRefreshToken(refreshToken: IToken) {
            this.refreshToken = refreshToken;
        },
        async refreshAccessToken() {
            try {
                const payload = {
                    bearerToken: this.accessToken.token,
                    refreshToken: this.refreshToken.token,
                };

                const { data: extendBearerTokenResponse } = await api.apiOnlineBookingExtendBearerTokenIklientPost(payload, {
                    metadata: {
                        requestType: RequestTypeEnum.REFRESH_TOKEN,
                    },
                });

                this.setAccessToken({
                    token: extendBearerTokenResponse.bearerToken,
                    expiresIn: DateTime.fromJSDate(extendBearerTokenResponse.bearerExpiration).toMillis(),
                });

                this.setRefreshToken({
                    token: extendBearerTokenResponse.refreshToken,
                    expiresIn: DateTime.fromJSDate(extendBearerTokenResponse.refreshExpiration).toMillis(),
                });

                return extendBearerTokenResponse.bearerToken;
            } catch (error) {
                console.error('Error refreshing access token', error);
                return '';
            }
        },
        logout() {
            const userStore = useUserStore();
            const wizardStore = useWizardStore();
            wizardStore.canGoBack = true;
            userStore.clear();
            this.$reset();
        },
    },
    persist: true,
});
