import Button from 'primevue/button';
import ConfirmationService from 'primevue/confirmationservice';
import FocusTrap from 'primevue/focustrap';
import InputText from 'primevue/inputtext';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import { createApp } from 'vue';
import './assets/main.scss';
import ScrollBar from '@/components/ScrollBar.vue';
import App from './App.vue';
import i18n from './plugins/i18n';
import pinia from './plugins/pinia';
import primeVuePlugin from './plugins/primeVue';
import router from './router';
import FontAwesomeIcon from './utils/fontAwesomeIcons';

const app = createApp(App);

app.use(router);
app.use(i18n);
app.use(pinia);

app.use(primeVuePlugin);
app.use(ToastService);
app.use(ConfirmationService);

app.directive('tooltip', Tooltip);
app.directive('focustrap', FocusTrap);

app.component('InputText', InputText);
app.component('Button', Button);
app.component('FontAwesomeIcon', FontAwesomeIcon);
app.component('ScrollBar', ScrollBar);

// great hack, this allows us to load up external cookie consent verification, and then load up edited plugin.js (the cookie script itself). We need to do this because their webhooks do not work, so we create our own.
window.document.body.appendChild = new Proxy(window.document.body.appendChild, {
    apply: function (target, that, args) {
        const el = args[0];
        if (el.src && el.src.includes('cdn.termsfeedtag.com') && el.src.includes('plugin.js')) el.src = '/customConsentPlugin/plugin.js';
        args[0] = el;
        return target.apply(that, args);
    },
});

app.mount('#app');
