import { DateTime } from 'luxon';
import { RequestTypeEnum } from '@/enums/RequestTypeEnum';
import Queue from '@/services/api/queue';
import { useAuthStore } from '@/stores/auth';
import { useUserStore } from '@/stores/user';

const queue = new Queue(false);
let isRefreshing = false;

export const requestInterceptor = async (config) => {
    const userStore = useUserStore();

    config.headers['Accept-Language'] = userStore.getLanguageCode;

    const abortController = new AbortController();

    // Abort the request if timeout is reached
    setTimeout(() => {
        abortController.abort();
    }, 30000);
    config.signal = abortController.signal;

    // If the request must be authenticated
    if (config.metadata?.requestType === RequestTypeEnum.AUTHENTICATED) {
        const authStore = useAuthStore();
        const dateNow = DateTime.now().toMillis();

        // Check if the refresh token is expired only if user have refresh token - we have only access token in some cases - eg. external login
        if (authStore.getRefreshToken.token) {
            // Check if the refresh token is expired
            const refreshTokenExpiration = authStore.getRefreshToken.expiresIn;

            // If the refresh token has expired, log out the user
            if (refreshTokenExpiration && dateNow >= refreshTokenExpiration) {
                authStore.logout();
                abortController.abort();
                return Promise.reject(new Error('Refresh token expired'));
            }
        }

        // Check if the access token is expired
        const accessTokenExpiration = authStore.getAccessToken.expiresIn;

        // If the access token has expired, refresh it
        if (accessTokenExpiration && dateNow >= accessTokenExpiration) {
            // If refresh token is not available, log out the user
            if (!authStore.getRefreshToken.token) {
                authStore.logout();
                abortController.abort();
                return Promise.reject(new Error('Login expired'));
            }

            if (!isRefreshing) {
                isRefreshing = true;

                const newAccessToken = await authStore.refreshAccessToken();

                if (!newAccessToken) {
                    isRefreshing = false;

                    // Clear the queue and log out the user if the access token could not be refreshed
                    queue.clear();
                    authStore.logout();
                    abortController.abort();
                    return Promise.reject(new Error('Error refreshing access token'));
                }

                config.headers.Authorization = `Bearer ${newAccessToken}`;

                isRefreshing = false;

                queue.drain();

                return config;
            } else {
                // Some other call already triggered of the refresh token add the current call to the queue
                await new Promise((resolve) => {
                    queue.add(function () {
                        const token = authStore.getAccessToken.token;

                        config.headers.Authorization = `Bearer ${token}`;
                        resolve(true);
                    });
                });

                return config;
            }
        } else {
            // If the access token has not expired, add it to the request config
            const token = authStore.getAccessToken.token;

            config.headers.Authorization = `Bearer ${token}`;
        }
    }

    // Otherwise, return the request config
    return config;
};

export const errorInterceptor = async (error) => {
    if (error.response && error.response.status === 401) {
        const authStore = useAuthStore();
        authStore.logout();
        return Promise.reject(error);
    } else {
        return Promise.reject(error);
    }
};
