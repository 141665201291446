export interface ILanguage {
    code: string;
    text: string;
    flag: string;
}

const languagesCodelist: Array<ILanguage> = [
    {
        code: 'cs',
        flag: '/flags/cz.svg',
        text: 'Česky',
    },
    {
        code: 'sk',
        flag: '/flags/sk.png',
        text: 'Slovensky',
    },
    {
        code: 'en',
        flag: '/flags/gb.svg',
        text: 'English',
    },
    {
        code: 'de',
        flag: '/flags/de.svg',
        text: 'Deutsch',
    },
    {
        code: 'uk',
        flag: '/flags/uk.svg',
        text: 'Українська',
    },
    {
        code: 'ru',
        flag: '/flags/ru.svg',
        text: 'Русский',
    },
];

export default languagesCodelist;
