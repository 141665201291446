export enum ROUTES {
    WELCOME = 'welcome',
    LOGIN = 'login',
    DASHBOARD = 'dashboard',
    WIZARD = 'wizard',
    CLINIC_SELECTION = 'clinic-selection',
    SERVICE_SELECTION = 'service-selection',
    STAFF_SELECTION = 'staff-selection',
    CLIENT_FORM = 'client-form',
    SUMMARY = 'summary',
    PAYMENT_SUMMARY = 'payment-summary',
    CANCEL_ORDER = 'cancel-order',
    NOT_FOUND = '404',
}
