import messages from '@intlify/unplugin-vue-i18n/messages';
import { isRef, nextTick } from 'vue';
import { createI18n } from 'vue-i18n';
import type { Composer, I18n, I18nMode, I18nOptions, Locale, VueI18n } from 'vue-i18n';

const datetimeFormats: I18nOptions['datetimeFormats'] = {
    cs: {
        short: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        },
    },
    sk: {
        short: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        },
    },
    en: {
        short: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        },
    },
    de: {
        short: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        },
    },
    ru: {
        short: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        },
    },
    uk: {
        short: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        },
    },
};

const numberFormats: I18nOptions['numberFormats'] = {
    cs: {
        currency: {
            style: 'currency',
            currency: 'CZK',
            useGrouping: true,
            notation: 'standard',
            currencyDisplay: 'symbol',
        },
        decimal: {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        },
        percent: {
            style: 'percent', // Format as a percentage
            minimumFractionDigits: 2, // Minimum number of decimal places
            maximumFractionDigits: 2, // Maximum number of decimal places
            useGrouping: false,
        },
    },
    en: {
        currency: {
            style: 'currency',
            currency: 'USD',
            useGrouping: true,
            notation: 'standard',
            currencyDisplay: 'symbol',
        },
        decimal: {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        },
        percent: {
            style: 'percent', // Format as a percentage
            minimumFractionDigits: 2, // Minimum number of decimal places
            maximumFractionDigits: 2, // Maximum number of decimal places
            useGrouping: false,
        },
    },
    de: {
        currency: {
            style: 'currency',
            currency: 'EUR',
            useGrouping: true,
            notation: 'standard',
            currencyDisplay: 'symbol',
        },
        decimal: {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        },
        percent: {
            style: 'percent', // Format as a percentage
            minimumFractionDigits: 2, // Minimum number of decimal places
            maximumFractionDigits: 2, // Maximum number of decimal places
            useGrouping: false,
        },
    },
    ru: {
        currency: {
            style: 'currency',
            currency: 'RUB',
            useGrouping: true,
            notation: 'standard',
            currencyDisplay: 'symbol',
        },
        decimal: {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        },
        percent: {
            style: 'percent', // Format as a percentage
            minimumFractionDigits: 2, // Minimum number of decimal places
            maximumFractionDigits: 2, // Maximum number of decimal places
            useGrouping: false,
        },
    },
    sk: {
        currency: {
            style: 'currency',
            currency: 'EUR',
            useGrouping: true,
            notation: 'standard',
            currencyDisplay: 'symbol',
        },
        decimal: {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        },
        percent: {
            style: 'percent', // Format as a percentage
            minimumFractionDigits: 2, // Minimum number of decimal places
            maximumFractionDigits: 2, // Maximum number of decimal places
            useGrouping: false,
        },
    },
    uk: {
        currency: {
            style: 'currency',
            currency: 'UAH',
            useGrouping: true,
            notation: 'standard',
            currencyDisplay: 'symbol',
        },
        decimal: {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        },
        percent: {
            style: 'percent', // Format as a percentage
            minimumFractionDigits: 2, // Minimum number of decimal places
            maximumFractionDigits: 2, // Maximum number of decimal places
            useGrouping: false,
        },
    },
};

const i18nConfig = {
    datetimeFormats,
    numberFormats,
    pluralRules: {
        cs: customRule,
        ru: customRule,
        sk: customRule,
        uk: customRule,
    },
    fallbackLocale: 'cs',
    globalInjection: true,
    legacy: false,
    locale: 'cs',
    messages,
    useScope: 'global',
};
function isComposer(instance: VueI18n | Composer, mode: I18nMode): instance is Composer {
    return mode === 'composition' && isRef(instance.locale);
}

export function setLocale(i18n: I18n, locale: Locale): void {
    if (isComposer(i18n.global, i18n.mode)) {
        i18n.global.locale.value = locale;
    } else {
        i18n.global.locale = locale;
    }
}

const i18n = setupI18n(i18nConfig);

export function setupI18n(options: I18nOptions): I18n {
    const i18n = createI18n(options);
    setI18nLanguage(i18n, options.locale!);
    return i18n;
}

export function setI18nLanguage(i18n: I18n, locale: Locale): void {
    setLocale(i18n, locale);
    document.querySelector('html')!.setAttribute('lang', locale);
}

const getResourceMessages = (r: any) => r.default || r;

const loadedLanguages = ['cs'];
export async function loadLocaleMessages(i18n: I18n, locale: Locale) {
    if (i18n.global.locale === locale) {
        return nextTick();
    }
    if (loadedLanguages.includes(locale)) {
        return nextTick();
    }

    // load locale messages
    const messages = await import(`../../translations/${locale}.json`).then(getResourceMessages);

    // set locale and locale message
    i18n.global.setLocaleMessage(locale, messages);

    // Add loaded locale to loadedLanguages
    loadedLanguages.push(locale);

    return nextTick();
}

function customRule(choice, choicesLength) {
    if (choice === 0) {
        return 0;
    }

    const teen = choice > 10 && choice < 20;
    const endsWithOne = choice % 10 === 1;
    if (!teen && endsWithOne) {
        return 1;
    }
    if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
    }

    return choicesLength < 4 ? 2 : 3;
}

export default i18n;
