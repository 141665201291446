import axios, { AxiosInstance } from 'axios';
import { setupCache, buildMemoryStorage, buildKeyGenerator, CacheRequestConfig } from 'axios-cache-interceptor';
import { requestInterceptor, errorInterceptor } from '@/services/api/interceptors';
import { OnlineBookingApi, PaymentGatewayApi } from '../../../generated';

// Create a new axios instance for the default API
const defaultAxiosInstance: AxiosInstance = axios.create();

// Cache
setupCache(defaultAxiosInstance, {
    methods: ['get', 'post'],
    storage: buildMemoryStorage(true, 1000 * 60 * 3, false), // 3 minutes cleanup interval
    debug: console.log,
    generateKey: buildKeyGenerator((request) => {
        return `${request.url}${JSON.stringify(request.params)}${JSON.stringify(request.data)}${JSON.stringify(request.headers ? request.headers['Authorization'] : '')}`;
    }),
});

defaultAxiosInstance.interceptors.request.use((request: CacheRequestConfig) => {
    if (request.url?.includes('GetAvailableTimesAll')) {
        request.cache = { ttl: 0 };
    }
    return requestInterceptor(request);
});
defaultAxiosInstance.interceptors.response.use((response) => response, errorInterceptor);

const api = new OnlineBookingApi(undefined, import.meta.env.VITE_API_BASE, defaultAxiosInstance);
const paymentApi = new PaymentGatewayApi(undefined, import.meta.env.VITE_API_BASE, defaultAxiosInstance);

export { paymentApi, api };
