import { ThemesEnum } from '@/enums/ThemesEnum';

export interface IBgTheme {
    idCodeList: ThemesEnum;
    themeName: string;
    isLight?: boolean;
    isSpecial: boolean;
}

const bgThemes: Array<IBgTheme> = [
    {
        idCodeList: ThemesEnum.BOOKING_SCHEME_LIGHT_LIGHT,
        themeName: 'light-light-bg-theme',
        isLight: true,
        isSpecial: false,
    },
    {
        idCodeList: ThemesEnum.BOOKING_SCHEME_LIGHT_DARK,
        themeName: 'light-dark-bg-theme',
        isLight: true,
        isSpecial: false,
    },
    {
        idCodeList: ThemesEnum.BOOKING_SCHEME_LIGHT_COLOR,
        themeName: 'light-color-bg-theme',
        isLight: true,
        isSpecial: true,
    },
    {
        idCodeList: ThemesEnum.BOOKING_SCHEME_DARK_LIGHT,
        themeName: 'dark-light-bg-theme',
        isLight: false,
        isSpecial: false,
    },
    {
        idCodeList: ThemesEnum.BOOKING_SCHEME_DARK_DARK,
        themeName: 'dark-dark-bg-theme',
        isLight: false,
        isSpecial: false,
    },
    {
        idCodeList: ThemesEnum.BOOKING_SCHEME_DARK_COLOR,
        themeName: 'dark-color-bg-theme',
        isLight: false,
        isSpecial: false,
    },
];

export default bgThemes;
