interface IQueue {
    running: (() => Promise<any>) | null;
    autorun: boolean;
    queue: Array<(value?: any) => Promise<any>>;
}

export default class Queue implements IQueue {
    running: (() => Promise<any>) | null;
    autorun: boolean;
    queue: Array<(value?: any) => Promise<any>>;

    constructor(autorun = true) {
        this.running = null;
        this.autorun = autorun;
        this.queue = [];
    }

    add(cb: (value?: any) => any): this {
        this.queue.push(async (value) => {
            try {
                const result = await cb(value);
                if (result !== false) {
                    this.dequeue();
                    return result;
                } else {
                    throw new Error('Callback returned false');
                }
            } catch (error) {
                console.error('Error executing callback:', error.message);
                this.dequeue();
                throw error;
            }
        });

        if (this.autorun && !this.running) {
            this.dequeue();
        }

        return this;
    }

    dequeue(): void {
        this.running = this.queue.shift() || null;

        if (this.running) {
            this.running().catch((error) => {
                console.error('Error executing queued callback:', error.message);
                this.dequeue();
            });
        }
    }

    drain(): void {
        while (this.queue.length) {
            this.dequeue();
        }
    }
    
    clear(): void {
        this.queue = [];
    }
}