import { defineStore } from 'pinia';

interface IState {
    isLoading: boolean;
}

export const useLoadingStore = defineStore('loading', {
    state: (): IState => ({
        isLoading: false,
    }),
    getters: {
        getIsLoading: (state: IState) => state.isLoading,
    },
    actions: {
        setIsLoading(isLoading: boolean) {
            this.isLoading = isLoading;
        },
    },
});
