export default {
    root: ({ props, context, parent }) => ({
        class: [
            'relative',

            // Alignments
            'items-center inline-flex text-center align-bottom justify-center',

            // Sizes & Spacing
            'leading-[normal]',
            {
                'text-normal px-4 py-4 md:px-6 md:py-4': props.size === null && props.label !== null,
                'text-sm px-2 py-3 md:px-4 md:py-3': props.size === 'small',
                'text-lg px-6 py-6 md:px-8 md:py-6': props.size === 'large',
            },
            {
                'w-12 p-0 py-3': props.label == null,
            },

            // Shapes
            { 'shadow-lg': props.raised },
            { 'rounded-md': !props.rounded, 'rounded-full': props.rounded },
            { 'rounded-none first:rounded-l-md last:rounded-r-md': parent.instance?.$name == 'InputGroup' },

            // Disabled
            { 'opacity-60 cursor-not-allowed': context.disabled },

            // Transitions
            'transition duration-200 ease-in-out',

            // Misc
            'overflow-hidden select-none',
        ],
    }),
    label: ({ props }) => ({
        class: [
            'duration-200',
            'font-bold',
            {
                'hover:underline': props.link,
            },
            { 'flex-1': props.label !== null, 'invisible w-0': props.label == null },
        ],
    }),
    icon: ({ props }) => ({
        class: [
            'mx-0',
            {
                'mr-2': props.iconPos == 'left' && props.label != null,
                'pl-8 order-1': props.iconPos == 'right' && props.label != null,
                'mb-2': props.iconPos == 'top' && props.label != null,
                'mt-2': props.iconPos == 'bottom' && props.label != null,
            },
        ],
    }),
    loadingicon: ({ props }) => ({
        class: [
            'h-4 w-4',
            'mx-0',
            {
                'mr-2': props.iconPos == 'left' && props.label != null,
                'ml-4 order-1': props.iconPos == 'right' && props.label != null,
                'mb-2': props.iconPos == 'top' && props.label != null,
                'mt-2': props.iconPos == 'bottom' && props.label != null,
            },
            'animate-spin',
        ],
    }),
    badge: ({ props }) => ({
        class: [{ 'ml-2 w-4 h-4 leading-none flex items-center justify-center': props.badge }],
    }),
};
