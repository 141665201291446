import PrimeVue, { PrimeVueChangeTheme, PrimeVueConfiguration, defaultOptions } from 'primevue/config';
import { usePassThrough } from 'primevue/passthrough';
import Tailwind from 'primevue/passthrough/tailwind';
import { toRaw } from 'vue';
import i18n from '@/plugins/i18n';
import accordion from '@/plugins/primeVue/pt/accordion';
import button from '@/plugins/primeVue/pt/button';
import calendar from '@/plugins/primeVue/pt/calendar';
import checkbox from '@/plugins/primeVue/pt/checkbox';
import dialog from '@/plugins/primeVue/pt/dialog';
import dropdown from '@/plugins/primeVue/pt/dropdown';
import inputmask from '@/plugins/primeVue/pt/inputmask';
import inputtext from '@/plugins/primeVue/pt/inputtext';
import menu from '@/plugins/primeVue/pt/menu';
import textarea from '@/plugins/primeVue/pt/textarea';
import tooltip from '@/plugins/primeVue/pt/tooltip';

const CustomTailwind = usePassThrough(Tailwind, {
    directives: {
        tooltip,
    },
    checkbox,
    inputtext,
    dropdown,
    menu,
    button,
    calendar,
    textarea,
    inputmask,
    dialog,
    accordion,
});

const primeVuePlugin = {
    install: (app) => {
        app.use(PrimeVue, {
            pt: CustomTailwind,
            ripple: false,
            unstyled: true,
            locale: {
                ...defaultOptions.locale,
                firstDayOfWeek: 1,
            },
        });
    },
};

export function setPrimeVueLanguage(primeVueInstance: { config: PrimeVueConfiguration; changeTheme: PrimeVueChangeTheme }, languageCode: string) {
    const baseKey = 'primeVue';
    const translationFn = i18n.global.t as (key: string) => string;

    const primeVueMessages = toRaw(i18n.global.getLocaleMessage(languageCode)[baseKey]);

    if (!primeVueMessages || !primeVueInstance || !primeVueInstance.config || !primeVueInstance.config.locale) {
        return;
    }

    function setNestedProperty(obj: Record<string, any>, path: string, value: any) {
        const keys = path.split('.');

        let current = obj;
        while (keys.length > 1) {
            const key = keys.shift()!;
            if (!current[key]) {
                current[key] = {};
            }
            current = current[key];
        }

        if (keys[0]) {
            current[keys[0]] = value;
        }
    }

    function updateLocale(messages: any, key?: string) {
        Object.keys(messages).forEach((messageKey) => {
            const value = messages[messageKey];
            const translationKey = `${baseKey}` + (key ? `.${key}` : '') + `.${messageKey}`;
            const primeVueConfigKey = key ? `${key}.${messageKey}` : messageKey;

            if (!primeVueInstance.config.locale) return;

            if (typeof value === 'function') {
                setNestedProperty(primeVueInstance.config.locale, primeVueConfigKey, translationFn(translationKey));
            } else if (Array.isArray(value)) {
                const arrayOfMessages: string[] = [];
                for (let i = 0; i < value.length; i++) {
                    arrayOfMessages.push(translationFn(`${translationKey}.${i}`));
                }
                setNestedProperty(primeVueInstance.config.locale, primeVueConfigKey, arrayOfMessages);
            } else if (typeof value === 'object' && value !== null) {
                updateLocale(value, primeVueConfigKey);
            } else {
                setNestedProperty(primeVueInstance.config.locale, primeVueConfigKey, value);
            }
        });
    }

    updateLocale(primeVueMessages);
}

export default primeVuePlugin;
