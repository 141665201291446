<template>
    <div v-show="loadingStore.isLoading" class="z-100 absolute flex h-screen w-screen flex-col items-center justify-center">
        <LottieAnimation class="h-[90vh]" ref="anim" :animation-data="XDENTLogo" :loop="true" :speed="1"> </LottieAnimation>
    </div>
</template>

<script setup lang="ts">
import { LottieAnimation } from 'lottie-web-vue';
import { onMounted, onUnmounted, ref, watch } from 'vue';
import XDENTLogo from '@/assets/xdentLogo.json';
import { useLoadingStore } from '@/stores/loading';

const anim = ref();
const loadingStore = useLoadingStore();

onMounted(() => {
    if (anim.value) {
        anim.value.play();
    }
});

watch(
    () => loadingStore.isLoading,
    (newValue) => {
        if (newValue) {
            anim.value.play();
        } else {
            anim.value.stop();
        }
    }
);

onUnmounted(() => {
    if (anim.value) {
        anim.value.destroy();
    }
});
</script>
