import { fileUrl } from '@/utils/fileUrl';

export function setDocumentTitle(iconId?: string | null, title?: string | null): void {
    document.title = title ? `${title} | ${import.meta.env.VITE_APP_NAME}` : import.meta.env.VITE_APP_NAME;
    // Icon
    if (!iconId) return;
    const link = (document.querySelector('link[rel="icon"]') as HTMLLinkElement) || (document.createElement('link') as HTMLLinkElement);
    link.rel = 'icon';
    link.href = fileUrl(iconId, 100, 100, 0);
    document.head.appendChild(link);
}
