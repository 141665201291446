export function setIndexing(state: boolean) {
    if (state === undefined) return;
    
    const metaTag = document.querySelector('meta[name="robots"]') as HTMLMetaElement;
    
    if (metaTag) {
        metaTag.content = state ? 'index,follow' : 'noindex,nofollow';
    } else {
        const metaTag = document.createElement('meta');
        metaTag.name = 'robots';
        metaTag.content = state ? 'index,follow' : 'noindex,nofollow';
        document.head.appendChild(metaTag);
    }
}