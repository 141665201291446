<template>
    <Toast />
    <router-view v-slot="{ Component }">
        <div class="size-full" v-show="!loadingStore.isLoading">
            <component :is="Component" />
        </div>
    </router-view>
    <LoadingOverlay v-if="loadingStore.isLoading" />
</template>

<script setup lang="ts">
import Toast from 'primevue/toast';
import { onMounted } from 'vue';
import LoadingOverlay from '@/pages/Overlays/LoadingOverlay.vue';
import { useLoadingStore } from '@/stores/loading';
import { useUserStore } from '@/stores/user';

const userStore = useUserStore();
const loadingStore = useLoadingStore();

onMounted(async () => {
    // Set language
    if (userStore.getLanguageCode) {
        await userStore.setLanguage(userStore.getLanguageCode);
    }
});
</script>
